import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "left" }}>
            An innovative <span className="purple">software engineer</span> with
            experience in backend development, cloud technologies, and cyber
            security.
            <br />
            <br />
            My expertise lies in:
            <ul>
              <li>Go, Python, and AWS technologies</li>
              <li>Developing and optimizing RESTful APIs</li>
              <li>
                Implementing business-driven outcomes in fast-paced environments
              </li>
              <li>
                Cross-functional collaboration and translating complex technical
                concepts for diverse stakeholders
              </li>
            </ul>
            <br />
            I'm passionate about building scalable, robust and accessible
            software solutions. My journey in tech began with a Master's in
            Software Development, where I gained a strong foundation in computer
            programming fundamentals and cloud technologies.
            <br />
            <br />
            My background in cyber security has given me a unique perspective on
            creating safer, more secure technology. I've conducted security
            assessments, developed strategic security architectures, and
            performed penetration testing.
            <br />
            <br />I also bring a creative edge to my work, drawing from my
            previous career as a media specialist in videography and creative
            imagery. This diverse background allows me to approach complex
            technical problems with a unique perspective.
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
